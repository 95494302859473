'use client'

import { useState, ReactNode } from 'react'
import { useTranslations } from 'next-intl'
import Button from '@/components/ViverseButton'
import Dialog from '@/components/ViverseDialog'

interface ConfirmDialogProps {
  onConfirm: () => void
  dialogTrigger: ReactNode
  title: string
  content: string | ReactNode
}

function ConfirmDialog({ onConfirm, dialogTrigger, title, content }: ConfirmDialogProps) {
  const t = useTranslations()
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      dialogTrigger={dialogTrigger}
      title={title}
      contentClassName="flex flex-col max-w-[420px] !min-w-0 p-[40px]"
      titleClassName="py-0 header-4 !h-auto !border-b-0"
      withCloseButton={false}
      open={open}
      onClose={handleClose}
      onOpenChange={(open) => setOpen(open)}
    >
      <div className="body-1 mb-8">{content}</div>
      <Button variant="colorful" onClick={onConfirm} size="large">
        {t('confirm-dialog.btn.ok')}
      </Button>
      <Button className="btn-blue-border mt-4 bg-transparent" size="large" onClick={handleClose}>
        {t('confirm-dialog.btn.cancel')}
      </Button>
    </Dialog>
  )
}

export default ConfirmDialog
