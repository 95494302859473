'use client'

import { HTMLAttributes, useState } from 'react'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import ViverseInput from '@/components/ViverseInput'
import ViverseButton from '@/components/ViverseButton'
import ViverseTextarea from '@/components/ViverseTextarea'
import DropdownSelect from '@/components/DropdownSelect'
import ViverseInputOTP from '@/components/ViverseInputOTP'
import { useRoomPolicy } from '@/hooks/useRoomPolicy'
import { useUploadFiles } from '@/hooks/useUploadFiles'
import { CreateAGiveawayRoomData } from '@/services/cms'
import { ImageAsset, Policy } from '@/types/world'
import { cn } from '@/lib/utils'

const PolicyTypeSchema = z.union([z.literal('public'), z.literal('private'), z.literal('unlisted')])

const formSchema = z.object({
  title: z.string().trim().min(1, { message: 'Required' }),
  description: z.string().optional(),
  policy: PolicyTypeSchema,
  passcode: z.string().optional(),
})

type WorldSettingsDefaultValues = {
  title: string
  description: string
  policy: Policy
}

interface WorldSettingsProps extends HTMLAttributes<HTMLFormElement> {
  defaultValues?: WorldSettingsDefaultValues
  defaultThumbnail?: ImageAsset
  sceneSid?: string
  onComplete: (data: CreateAGiveawayRoomData) => void
  mode?: 'create' | 'update'
}

export const WORLD_SETTINGS_DEFAULT_VALUES: WorldSettingsDefaultValues = {
  title: '',
  description: '',
  policy: 'private',
}

function WorldSettings({
  defaultValues = WORLD_SETTINGS_DEFAULT_VALUES,
  defaultThumbnail,
  sceneSid,
  onComplete,
  mode = 'create',
  ...props
}: WorldSettingsProps) {
  const t = useTranslations()
  const ROOM_POLICY = useRoomPolicy()
  const [policy, setPolicy] = useState<keyof typeof ROOM_POLICY>(
    defaultValues.policy || WORLD_SETTINGS_DEFAULT_VALUES.policy,
  )

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    console.log(
      {
        policy: ROOM_POLICY[policy].value,
        title: values.title,
        description_plaintext: values.description,
        passcode: values.passcode,

        scene_sid: sceneSid,
        features: {
          spatial_sound: true,
        },
        image: uploadedFile?.id || defaultThumbnail?.id,
      },
      'the params for giveaway, create-room',
    )
    onComplete({
      policy: ROOM_POLICY[policy].value,
      title: values.title,
      description_plaintext: values.description || '',
      passcode: values.passcode,

      scene_sid: sceneSid || '',
      features: {
        spatial_sound: true,
      },
      image: uploadedFile?.id || defaultThumbnail?.id || '',
    })
  }

  const handlePolicyValueChange = (value: string) => {
    const policySet = Object.entries(ROOM_POLICY).find(([key, _]) => _.value === value)
    if (policySet) {
      if (policySet[0] !== 'private') {
        form.setValue('passcode', '')
      }
      setPolicy(policySet[0] as keyof typeof ROOM_POLICY)
    }
  }

  const isFormSubmitDisabled = !!form.formState.errors.title

  const {
    inputRef,
    handleFileUpload,
    handleInputChange,
    isFileUploading,
    uploadedFile,
    error: uploadError,
  } = useUploadFiles()

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex w-full flex-col items-center px-5"
        {...props}
      >
        <div className="flex w-full flex-col md:flex-row">
          <div id="upload-thumbnail-panel" className="min-h-[300px] text-center md:w-2/5">
            <div className="mb-2">
              <div className="text-left">
                {t('profile-page.my-worlds-card-settings-thumbnail-select')}
              </div>
              <div className="caption text-left text-vive-primary-40">
                {t('profile-page.my-worlds-card-settings-thumbnail-maximum')}
              </div>
            </div>
            <div className="relative h-3/5 w-full overflow-hidden rounded-lg">
              {isFileUploading ? (
                <div>loading...</div>
              ) : (
                <Image
                  src={uploadedFile?.url || defaultThumbnail?.url || '/images/room-default.png'}
                  alt="uploaded image of room"
                  style={{ objectFit: 'cover' }}
                  fill
                  unoptimized
                />
              )}
            </div>
            {uploadError && <div className="caption">{uploadError.message}</div>}
            <ViverseButton className="mt-2" onClick={handleFileUpload} variant="secondary">
              {t('profile-page.my-worlds-card-settings-thumbnail')}
            </ViverseButton>
          </div>

          <div className="md:ml-5 md:flex-1">
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('profile-page.my-worlds-card-settings-title')}</FormLabel>
                  <FormControl>
                    <ViverseInput
                      placeholder={t('profile-page.my-worlds-card-settings-title-caption')}
                      maxLength={30}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem className="mt-4">
                  <FormLabel>{t('profile-page.my-worlds-card-settings-description')}</FormLabel>
                  <FormControl>
                    <ViverseTextarea
                      placeholder={t('profile-page.my-worlds-card-settings-description-caption')}
                      maxLength={200}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>

        <FormField
          control={form.control}
          name="policy"
          render={({ field }) => (
            <FormItem className="mt-4 w-full flex-1">
              <FormLabel>{t('profile-page.my-worlds-card-settings-access')}</FormLabel>
              <FormControl>
                <div id="policy" className="mb-4 mt-2 w-full">
                  <DropdownSelect onValueChange={handlePolicyValueChange} {...field}>
                    <DropdownSelect.Trigger className="h-14 min-w-max border-2 border-vive-primary-70 bg-vive-popup">
                      <DropdownSelect.Value
                        asChild
                        title={ROOM_POLICY[policy].title}
                        description={ROOM_POLICY[policy].description}
                      />
                    </DropdownSelect.Trigger>
                    <DropdownSelect.Content className="border-2 border-vive-primary-70 bg-vive-popup">
                      {Object.values(ROOM_POLICY).map(({ value, title, description }) => (
                        <DropdownSelect.Item
                          key={`dropdown-select-item-${value}`}
                          value={value}
                          title={title}
                          description={description}
                        />
                      ))}
                    </DropdownSelect.Content>
                  </DropdownSelect>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="passcode"
          render={({ field }) => (
            <FormItem className={cn({ invisible: policy === 'private' }, 'mt-4 w-full flex-1')}>
              <FormLabel>{t('profile-page.my-worlds-card-settings-passcode')}</FormLabel>
              <FormControl>
                <ViverseInputOTP {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <ViverseButton type="submit" disabled={isFormSubmitDisabled} className="mt-4">
          {mode === 'create'
            ? t('profile-page.my-worlds-card-settings-create-button')
            : t('profile-page.my-worlds-card-settings-save-button')}
        </ViverseButton>
      </form>
      <input type="file" ref={inputRef} style={{ display: 'none' }} onChange={handleInputChange} />
    </Form>
  )
}

export default WorldSettings
