import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { SelectArrow } from '@radix-ui/react-select'
import { ComponentProps, PropsWithChildren } from 'react'

interface DropdownSelectProps extends PropsWithChildren<ComponentProps<typeof Select>> {}

function DropdownSelect(props: DropdownSelectProps) {
  return <Select {...props} />
}

interface DropdownSelectTriggerProps
  extends PropsWithChildren<ComponentProps<typeof SelectTrigger>> {}

function DropdownSelectTrigger({ children, ...props }: DropdownSelectTriggerProps) {
  return (
    <SelectTrigger
      className="h-14 min-w-max border-2 border-vive-primary-70 bg-vive-popup"
      {...props}
    >
      {children}
    </SelectTrigger>
  )
}

interface DropdownSelectValueProps extends PropsWithChildren<ComponentProps<typeof SelectValue>> {
  title: string
  description: string
}

function DropdownSelectValue({ title, description, children, ...props }: DropdownSelectValueProps) {
  return (
    <SelectValue asChild {...props}>
      <div className="flex h-14 flex-col justify-center text-left text-white">
        <div>{title}</div>
        <div>{description}</div>
      </div>
    </SelectValue>
  )
}

interface DropdownSelectContentProps
  extends PropsWithChildren<ComponentProps<typeof SelectContent>> {}

function DropdownSelectContent({ children, ...props }: DropdownSelectContentProps) {
  return (
    <SelectContent className="border-2 border-vive-primary-70 bg-vive-popup" {...props}>
      {children}
    </SelectContent>
  )
}

export interface DropdownSelectItemProps
  extends PropsWithChildren<ComponentProps<typeof SelectItem>> {
  value: string
  title: string
  description: string
}

function DropdownSelectItem({ title, description, ...props }: DropdownSelectItemProps) {
  return (
    <SelectItem
      className="cursor-pointer focus:bg-vive-primary-70 focus:hover:bg-vive-primary-70"
      {...props}
    >
      <div className="flex h-14 flex-col justify-center text-left text-white">
        <div>{title}</div>
        <div>{description}</div>
      </div>
    </SelectItem>
  )
}

DropdownSelect.Trigger = DropdownSelectTrigger
DropdownSelect.Value = DropdownSelectValue
DropdownSelect.Content = DropdownSelectContent
DropdownSelect.Item = DropdownSelectItem

export default DropdownSelect
