import { useConfigsContext } from '@/context/ConfigsContext'
import { FileValidator } from '@/lib/validator'
import { fetchUploadRoomImage } from '@/services/cms'
import { ImageAsset } from '@/types/world'
import { ChangeEvent, MouseEventHandler, useRef, useState } from 'react'

export function useUploadFiles(maxFileSize = 2) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [error, setError] = useState<Error>()

  const [uploadedFile, setUploadedFile] = useState<ImageAsset>()
  const { store } = useConfigsContext()
  const htcToken = store?.credentials?.htcToken

  const handleFileUpload: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()

    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    const hasFiles = e.target.files && e.target.files.length > 0
    const files = hasFiles ? e.target.files : undefined

    if (files) {
      try {
        await FileValidator.validateFileSize(files, maxFileSize)
        await setIsFileUploading(true)
        // call the upload-asset api to upload the file asset to htc cms server, right now only support one fie for once
        if (htcToken) {
          const response = await fetchUploadRoomImage(htcToken, files[0])
          if (response?.status === 200 && response?.results) {
            setUploadedFile(response?.results as any)
          }
        } else {
          throw new Error('missing htcToken')
        }
      } catch (error) {
        console.log('Upload files failed: ', error)
        setError(error as Error)
      } finally {
        setIsFileUploading(false)
      }
    }
  }
  return {
    handleFileUpload,
    handleInputChange,
    isFileUploading,
    uploadedFile,
    inputRef,
    error,
  }
}
