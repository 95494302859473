import { ChangeEvent, useState } from 'react'
import { cn } from '@/lib/utils'
import { Input, InputProps } from '@/components/ui/input'

interface ViverseInputProps extends InputProps {}

function ViverseInput({ className, onChange, maxLength, ...props }: ViverseInputProps) {
  const [valueLength, setValueLengh] = useState(0)
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e)
    setValueLengh(e.target.value.length)
  }

  return (
    <div
      className={cn(
        'box-border flex items-center rounded-md border-2 border-vive-primary-70 bg-vive-popup transition-all duration-300 focus-within:border-4 focus-within:border-vive-border-focus',
        className,
      )}
    >
      <Input
        placeholder="Email"
        className="h-8 border-none bg-vive-popup text-white"
        onChange={handleChange}
        maxLength={maxLength}
        {...props}
      />
      {maxLength && (
        <div className="h-full w-max text-nowrap px-3">
          <span className="caption">
            {valueLength} / {maxLength}
          </span>
        </div>
      )}
    </div>
  )
}

export default ViverseInput
