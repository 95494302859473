import { ComponentProps, PropsWithChildren, ReactNode } from 'react'
import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { cn } from '@/lib/utils'

export type ViverseCardProps = PropsWithChildren<ComponentProps<typeof Card>> & {}

function ViverseCard({ children, className, ...props }: ViverseCardProps) {
  return (
    <Card
      {...props}
      className={cn(
        'cursor-pointer border-0 bg-transparent',
        className,
      )}
    >
      {children}
    </Card>
  )
}

export type ViverseCardHeaderProps = PropsWithChildren<ComponentProps<typeof CardHeader>> & {}

ViverseCard.Header = function ViverseCardHeader({
  children,
  className,
  ...props
}: ViverseCardHeaderProps) {
  return (
    <CardHeader className={cn('mt-[6px] p-0', className)} {...props}>
      {children}
    </CardHeader>
  )
}

export type ViverseCardContentProps = PropsWithChildren<ComponentProps<typeof CardContent>> & {}

ViverseCard.Content = function ViverseCardContent({
  children,
  className,
  ...props
}: ViverseCardHeaderProps) {
  return (
    <CardContent className={cn('relative overflow-hidden rounded-[4px] p-0', className)} {...props}>
      {children}
    </CardContent>
  )
}

export default ViverseCard
