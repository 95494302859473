export class FileValidator {
  static validateFileSize = (files: FileList, size = 2) => {
    const maxFileSize = size * 1024 * 1024

    const handleSizeCheck = () => {
      const isValidCheckList = []
      for (let i = 0; i < files.length; i++) {
        isValidCheckList.push(files[i].size < maxFileSize)
      }

      return isValidCheckList.includes(false) ? false : true
    }
    return new Promise((resolve, reject) => {
      const result = handleSizeCheck()
      return result ? resolve(files) : reject(new Error('File size exceeds limit.'))
    })
  }
}
