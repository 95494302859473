import { Host } from '@/services/cms'

export function numberFormatter(n: number): string {
  const length = n.toString().length
  let result = `${n}`

  if (length > 4) {
    result = `${Math.round(n / 1000)}k`
  }
  if (length > 7) {
    result = `${Math.round(n / 1000000)}m`
  }
  if (length > 10) {
    result = `${Math.round(n / 1000000000)}b`
  }

  return result
}

export function combineNames(names: string[]) {
  return names.join(' x ')
}
