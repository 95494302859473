import { MouseEvent, useState } from 'react'

export type SocialPlatform = 'facebook' | 'twitter'

export const SOCIAL_URLS = {
  facebook: 'https://www.facebook.com/sharer/sharer.php',
  twitter: 'https://twitter.com/intent/tweet',
}

export function useShareLink(link?: string) {
  const [linkIsCopied, setLinkIsCopied] = useState(false)

  function copyLink(e: MouseEvent<HTMLDivElement>) {
    if (!link) return

    navigator.clipboard.writeText(link)
    setLinkIsCopied(true)
  }

  function shareToPlatform(platform: SocialPlatform) {
    let socialLink = link

    switch (platform) {
      case 'facebook': {
        socialLink = `${SOCIAL_URLS.facebook}?u=${link}`
        break
      }
      case 'twitter': {
        socialLink = `${SOCIAL_URLS.twitter}?text=Explore the Worlds %7C VIVERSE ${link}`
        break
      }
      default: {
      }
    }

    console.log('[useShareLink]: socialLink: ', socialLink)
    window.open(socialLink)
  }

  return {
    linkIsCopied,
    setLinkIsCopied,
    copyLink,
    shareToPlatform,
  }
}
