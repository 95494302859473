import { useTranslations } from 'next-intl';
import { Policy, PolicyOption } from '@/types/world';

export const useRoomPolicy = (): Record<Policy, PolicyOption> => {
  const t = useTranslations();

  const ROOM_POLICY: Record<Policy, PolicyOption> = {
    public: {
      value: 'allow_any_user',
      title: t('profile-page.my-worlds-create-option-1-title'),
      description: t('profile-page.my-worlds-create-option-1-desc'),
    },
    unlisted: {
      value: 'unlisted',
      title: t('profile-page.my-worlds-create-option-2-title'),
      description: t('profile-page.my-worlds-create-option-2-desc'),
    },
    private: {
      value: 'owner_only',
      title: t('profile-page.my-worlds-create-option-3-title'),
      description: t('profile-page.my-worlds-create-option-3-desc'),
    },
  };

  return ROOM_POLICY;
};
