import { ComponentProps, HTMLAttributes, MouseEvent, PropsWithChildren } from 'react'
import { Card, CardContent } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { cn } from '@/lib/utils'

interface PopoverMenuProps extends PropsWithChildren<ComponentProps<typeof Card>> {}

function PopoverMenu({ className, children, ...props }: PopoverMenuProps) {
  return (
    <Card
      className={cn(
        'w-fit border-2 border-solid border-vive-primary-70 bg-vive-popup px-3 py-2 text-vive-primary-20',
        className,
      )}
      {...props}
    >
      <CardContent className="flex flex-col gap-1 p-0">{children}</CardContent>
    </Card>
  )
}

export default PopoverMenu

interface PopoverMenuItemProps extends HTMLAttributes<HTMLDivElement> {
  isActive?: boolean
}

export function PopoverMenuItem({
  isActive = false,
  onClick,
  children,
  ...props
}: PopoverMenuItemProps) {
  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    onClick && onClick(e)
  }

  return (
    <div
      className={cn(
        'body-1 flex min-w-[190px] cursor-pointer items-center gap-2 rounded p-2 hover:bg-vive-popup-hover',
        { 'text-vive-brand-50': isActive },
      )}
      onClick={handleClick}
      {...props}
    >
      {children}
    </div>
  )
}

PopoverMenu.Item = PopoverMenuItem

interface PopoverMenuItemsGroupProps extends HTMLAttributes<HTMLDivElement> {}

export function PopoverMenuItemsGroup({ title, children }: PopoverMenuItemsGroupProps) {
  return (
    <div>
      {title && <div className="caption px-2 py-1 text-vive-primary-40">{title}</div>}
      {children}
    </div>
  )
}

PopoverMenu.Group = PopoverMenuItemsGroup

export function PopoverMenuSeperator() {
  return <Separator className="h-0.5 bg-vive-primary-70" />
}

PopoverMenu.Seperator = PopoverMenuSeperator
