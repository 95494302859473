import { ChangeEvent, ChangeEventHandler, ComponentProps, PropsWithChildren, useState } from 'react'
import { Textarea } from '@/components/ui/textarea'
import { cn } from '@/lib/utils'

interface ViverseTextareaProps extends PropsWithChildren<ComponentProps<typeof Textarea>> {}

function ViverseTextarea({
  className,
  children,
  maxLength,
  onChange,
  ...props
}: ViverseTextareaProps) {
  const [valueLength, setValueLengh] = useState(0)
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(e)
    setValueLengh(e.target.value.length)
  }

  return (
    <div
      className={cn(
        'focus-within:border-vive-border-focus box-border flex items-center rounded-md border-2 border-vive-primary-70 bg-vive-popup transition-all duration-300 focus-within:border-4',
        className,
      )}
    >
      <Textarea
        className={cn('resize-none border-none bg-vive-popup text-white', className)}
        onChange={handleChange}
        maxLength={maxLength}
        {...props}
      >
        {children}
      </Textarea>
      {maxLength && (
        <div className="h-full w-max text-nowrap px-3">
          <span className="caption">
            {valueLength} / {maxLength}
          </span>
        </div>
      )}
    </div>
  )
}

export default ViverseTextarea
